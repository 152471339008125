import React, { useState, useEffect } from 'react';
import moment from 'moment';
import qs from 'qs';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_SUBCATEGORY, GET_DOCUMENT } from '../../queries';
import Loading from '../modules/Loading';
import Paper from '../modules/Paper';
import TabBar from '../modules/TabBar';
import Table from '../modules/Table';
import Tooltip from '../modules/Tooltip';
import Icon from '../modules/Icon';
import { Body, Heading } from '../modules/Typography';
import Section from '../document/Section';
import Spec from '../document/Spec';
import Print from './Print.js';
import parseMarkdown from '../../utils/markdownParser';
import logo from '../../assets/Specs_Logo_Blue.png';
import { documentSpecsFilter } from '../../utils/documentSpecsFilter.js';

const Document = (props) => {
  const { catSlug, subSlug, slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = qs.parse(location.search.slice(1));
  const [activeTab, setActiveTab] = useState('');
  const [tabs, setTabs] = useState([]);
  const [activeSlug, setActiveSlug] = useState('');
  const [showDetail, setShowDetail] = useState(true);
  const [activeItem, setActiveItem] = useState('');
  const [caret, setCaret] = useState('caret-down');
  const [section, setSection] = useState([]);
  const [doc, setDoc] = useState(null);
  const [printSectionSlug, setPrintSectionSlug] = useState(null);
  const [fullDocumentPrint, setFullDocumentPrint] = useState(false);

  const [data, setData] = useState();

  const [getDocument, { data: documentData, loading }] = useLazyQuery(
    GET_DOCUMENT,
    {
      variables: { slug },
    },
  );

  const { data: subData, loading: subLoading } = useQuery(GET_SUBCATEGORY, {
    variables: { catSlug, subSlug },
  });

  useEffect(() => {
    if (documentData) {
      setData(documentSpecsFilter(documentData));
    }
  }, [documentData]);

  useEffect(() => {
    if (data && data.documents.data.length === 0 && !subLoading) {
      const firstDocumentSlug =
        subData.subcategorieses.data[0].attributes.documentContainer[0].document
          .data.attributes.slug;
      navigate(`../${catSlug}/${subSlug}/${firstDocumentSlug}`, {
        replace: true,
      });
    }
    if (data && data.documents.data.length) {
      setFullDocumentPrint(
        data?.documents.data[0].attributes.fullDocumentPrint,
      );
    }
  }, [data, subData]);

  useEffect(() => {
    setPrintSectionSlug(query.tab);
    setActiveSlug(query.tab);
  }, []);

  useEffect(() => {
    if (!doc || doc.slug !== slug) {
      getDocument();
      setActiveSlug('');
      setPrintSectionSlug(query.tab);
    }
  }, [slug]);

  useEffect(() => {
    if (data && data.documents.data.length > 0) {
      setDoc(data.documents.data[0]);
      window.document.title = `Disney Media Tech Specs - ${
        data ? data.documents.data[0].attributes.name : ''
      }`;
    }
  }, [data]);

  useEffect(() => {
    if (data && data.documents.data.length > 0 && doc) {
      const { section } = doc.attributes;
      if (section.length) {
        const tabs = section.map((sect) => sect.name);
        setTabs(tabs);
        let tab = tabs[0];
        let firstSection = section[0];
        if (activeSlug === '') {
          // Bug fix: When visiting a tab from a direct link, it should look at what's provided
          // first before defaulting to the first section
          if (query.tab) {
            setActiveSlug(query.tab);
            setPrintSectionSlug(query.tab);
          } else {
            setActiveSlug(firstSection.slug);
            setPrintSectionSlug(firstSection.slug);
          }
        }
        if (query.tab) {
          const slugSection = section.find((sect) => sect.slug === query.tab);
          if (slugSection) {
            tab = slugSection.name;
            firstSection = slugSection;
          }
        }
        setActiveTab(tab);
        setPrintSectionSlug(tab.toLowerCase());
        setSection(
          firstSection.specContainer.map((container) => container.spec),
        );
      } else {
        setTabs([]);
        setSection([]);
      }
    }
  }, [doc, query.tab]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) & (event.key === 'p')) {
        setPrintSectionSlug(query.tab);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (
    (data && data.documents.data.length === 0 && !data.attributes) ||
    !doc ||
    subLoading ||
    loading
  ) {
    return <Loading />;
  }

  const handleSectionPrint = (slug) => {
    setPrintSectionSlug(slug);
  };

  const handleTab = (tab) => {
    const selectedSlug = doc.attributes.section.find(
      (sect) => sect.name === tab,
    ).slug;
    setActiveSlug(selectedSlug);
    if (catSlug === 'preview') {
      navigate(`/preview/preview/${slug}?tab=${selectedSlug}`);
    } else {
      navigate(`/${catSlug}/${subSlug}/${slug}?tab=${selectedSlug}`);
    }
  };

  const handleDetail = (id) => {
    setActiveItem(id);
    const details = document.querySelector(`#Updates__detail__${id}`);
    const allDetails = details.getElementsByTagName('li');
    if (showDetail) {
      for (let i = 0; i < allDetails.length; i++) {
        allDetails[i].style.display = 'list-item';
      }
      setShowDetail(false);
      setCaret('caret-up');
    } else if (showDetail === false) {
      for (let i = 0; i < allDetails.length; i++) {
        allDetails[i].style.display = 'none';
      }
      setShowDetail(true);
      setCaret('caret-down');
    }
  };

  return (
    <div className="Document">
      <div className="Document__coverpage">
        <img className="Print__logo" src={logo} />
        {fullDocumentPrint === true ? (
          <Heading number={5} heavy block className="Document__title cover">
            {doc.attributes.name}
          </Heading>
        ) : (
          <Heading number={5} heavy block className="Document__title cover">
            {activeTab}
          </Heading>
        )}
        <Body number={2} className="Document__date cover">
          {doc.attributes.publishDate ? doc.attributes.publishDate : ''}
        </Body>
        {doc.attributes.updates.length && fullDocumentPrint ? (
          <Paper className="Document__Updates__print">
            <Heading
              number={5}
              heavy
              block
              className="Document__Updates__title">
              Updates
            </Heading>
            <Table
              data={doc.attributes.updates
                .slice(-5)
                .sort((a, b) => {
                  if (moment(b.date).isBefore(moment(a.date))) {
                    return -1;
                  }
                  return 1;
                })
                .map((update) => [
                  update.publishDate,
                  update.category,
                  parseMarkdown(update.description),
                ])}
              className="Document__Updates__table"
            />
          </Paper>
        ) : (
          ''
        )}
        {fullDocumentPrint && (
          <>
            <Heading number={5} heavy block className="Document__TOC">
              Table of Contents
            </Heading>
            {data.documents.data[0].attributes.section.length
              ? data.documents.data[0].attributes.section.map((section, i) => (
                  <>
                    <a className="Document__TOC__title" href={`#toc_${i + 1}`}>
                      {i + 1}. {section.name}
                    </a>
                    {section.specContainer.map((spec, idx) => (
                      <div key={idx} className="Document__TOC__subtitle">
                        {i + 1}.{idx + 1}
                        <a
                          className="Document__TOC__subtitle__link"
                          href={`#toc_${i + 1}.${idx + 1}`}>
                          {spec.spec.data.attributes.name}
                        </a>
                      </div>
                    ))}
                  </>
                ))
              : ''}
          </>
        )}
      </div>
      <div className="Document__header">
        <Heading number={5} heavy block className="Document__title">
          {/* {doc.attributes.subcategories.data[0].attributes.name} */}
          {subData.subcategorieses.data[0].attributes.name}
        </Heading>
        <div className="flex align-center">
          <Body number={2} className="Document__date">
            {doc.attributes.publishDate ? doc.attributes.publishDate : ''}
          </Body>
          {fullDocumentPrint && (
            <Tooltip content="PDF Download" direction="left">
              <a
                className="Document__updated__container"
                href="javascript:window.print()">
                <Icon
                  name="print"
                  className="Document__updated__icon"
                  size="medium"
                />
              </a>
            </Tooltip>
          )}
        </div>
      </div>
      <Paper className="flex column" elevation={6}>
        {fullDocumentPrint === true ? (
          <Heading number={5} heavy className="Document__name">
            {doc.attributes.name}
          </Heading>
        ) : (
          <Heading number={5} heavy className="Document__name cover">
            {`${activeTab}`}
          </Heading>
        )}

        <Body number={2}>{parseMarkdown(doc.attributes.description)}</Body>
      </Paper>
      <Paper elevation={1} className="Document__Paper">
        {tabs.length ? (
          <TabBar
            tabs={tabs}
            selectedTab={activeTab}
            updateTab={handleTab}
            className="Document__Tabs"
          />
        ) : (
          ''
        )}
        {!fullDocumentPrint && (
          <Tooltip printSection content="PDF Download" direction="left">
            <a
              className="Document__updated__container"
              onClick={() => handleSectionPrint(query.tab)}
              href="javascript:window.print()">
              <Icon
                name="print"
                className="Document__updated__icon"
                size="medium"
              />
            </a>
          </Tooltip>
        )}
        {section.length ? (
          <Section sectionSlug={activeSlug} section={section} />
        ) : (
          <Spec spec={{ content: doc.attributes.content }} />
        )}
      </Paper>
      {doc.attributes.updates.length ? (
        <Paper className="Document__Updates">
          <Heading number={5} heavy block className="Document__Updates__title">
            Updates
          </Heading>
          <Table
            data={doc.attributes.updates
              .slice(-3)
              .sort((a, b) => {
                if (moment(b.date).isBefore(moment(a.date))) {
                  return -1;
                }
                return 1;
              })
              .map((update, idx) => [
                update.publishDate,
                update.category,
                <>
                  <div
                    className="Document__Updates__details"
                    id={`Updates__detail__${idx}`}>
                    {parseMarkdown(update.description)}
                  </div>
                </>,

                <>
                  {update.description ? (
                    update.description.includes('<ul>') ? (
                      <Icon
                        className="Document__Updates__caret cursor-pointer "
                        name={idx === activeItem ? caret : 'caret-down'}
                        onClick={() => handleDetail(idx)}
                      />
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                </>,
              ])}
            className="Document__Updates__table"
          />
        </Paper>
      ) : (
        ''
      )}
      <Print
        data={data}
        documentPrint={fullDocumentPrint}
        printSectionSlug={printSectionSlug || activeSlug}
      />
      <div className="footer hide">
        © Disney |{' '}
        {doc.attributes.subcategories.data[0].attributes.category
          ? doc.attributes.subcategories.data[0].attributes.category.data
              .attributes.name
          : ''}{' '}
        {'>'} {doc.attributes.subcategories.data[0].attributes.name} {'>'}{' '}
        {doc.attributes.name}{' '}
      </div>
    </div>
  );
};

export default Document;
